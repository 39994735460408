.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 10vh;
}

.Header-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Actions-link {
  flex: 1;
  font-size: 1.5rem;
  color: #fff;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: transparent;
  border: none;
  width: 8rem;
  height: 2rem;
}
.App-body {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 80vh;
}

.App-footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 10vh;
}
